<template>
  <div class="max-w-lg mr-auto ml-auto text-justify">
    <div class="d-flex justify-content-center align-items-center">
      <JayaTitle titleName="Mes observations"/>
    </div>

    <transition-group class="incard" name="list">
      <p v-if="observations.length === 0" key="noteNull" class="text-center">
        Vous n'avez encore réalisé aucune observation d'animaux dans vos jardins ! Pour ajouter une observation, rendez-vous sur l'onglet faune de l'
        <span class="text-primary cursor-pointer" @click="$router.push({name: 'jayaccueil', hash: '#encyclopedie'})">
          encyclopédie.
        </span>
      </p>
      <ObservationCard v-else v-for="(observation) in observations" :key="observation.id" ref="observationCards" :observation="observation"/>
    </transition-group>
    <jaya-divider color="transparent"/>
  </div>
</template>

<script>
import JayaTitle from '@/components/global/JayaTitle.vue'
import ObservationCard from '@/components/animals/ObservationCard.vue'

export default {
  components: {
    JayaTitle,
    ObservationCard,
  },
  data() {
    return {
      fetchOffset: 0,
      fetchLimit: 10,
      fetchNext: null,
      waitScroll: false,
      dateKey: 0,
    }
  },
  mounted() {
    window.addEventListener('scroll', this.scroll)
    this.fetchObservations()
  },
  destroyed() {
    window.removeEventListener('scroll', this.scroll)
  },
  computed: {
    fetchPayload() {
      return {
        limit: this.fetchLimit,
        offset: this.fetchOffset,
      }
    },
    observations() {
      return this.$store.state.animal.observations
    },
  },
  methods: {
    scroll() {
      if (this.fetchNext && document.body.scrollHeight - window.scrollY - window.innerHeight < 50) {
        this.loadNext()
      }
    },
    fetchObservations() {
      this.fetchOffset = 0
      this.$store.dispatch('animal/fetchObservations', this.fetchPayload)
          .then((response) => {
            this.fetchNext = response.next
          })
          .catch(() => {
            this.$notify({
              time: 4000,
              color: 'danger',
              title: 'Erreur',
              text: 'Une erreur est survenue lors de la récupération des observations'
            })
          })
    },
    loadNext() {
      if (!this.waitScroll) {
        this.waitScroll = true
        this.fetchOffset = this.fetchOffset + this.fetchLimit
        this.$store.dispatch('animal/fetchObservations', this.fetchPayload)
            .then((nextResponse) => {
              this.fetchNext = nextResponse.next
              this.waitScroll = false
            })
            .catch(() => {
              this.$notify({
                time: 4000,
                color: 'danger',
                title: 'Erreur',
                text: 'Une erreur est survenue lors de la récupération des observations'
              })
              this.waitScroll = false
            })
      }
    },
  },
  watch: {
    fetchNext(value) { //get at least 10 observation cards
      if (!!value && this.observations.length < 10) {
        this.loadNext()
      }
    },
  }
}
</script>
