<template>
  <div class="jaya-title d-flex justify-content-center">
    <div :class="classValue">
      <div class="relative">
        <JayaTooltip v-if="moreInfo!=''" :text="moreInfo" btnColor="light" position="top" title="En savoir plus">
          <div class="d-flex justify-content-center">
            <h2 :class="textClassValue" class="text-center"> {{ titleName | uppercase }}</h2>
            <div class="more-info text-help"> ?</div>
          </div>
        </JayaTooltip>
        <div v-else :class="clickable ? 'cursor-pointer rounded-lg' : ''" class="d-flex justify-content-center" @click="titleClicked">
          <h2 :class="textClassValue" class="text-center"> {{ titleName | uppercase }}</h2>
          <div v-if="clickable" class="more-info text-info"> ?</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">

export default {
  name: "jaya-title",
  props: {
    titleName: {
      type: String,
      default: "Title"
    },
    iconType: {
      type: String,
      default: "search"
    },
    classType: {
      type: String,
      default: ""
    },
    moreInfo: {
      type: String,
      default: ''
    },
    clickable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    iconValue() {
      return "icon-" + this.iconType
    },
    classValue() {
      return "title " + this.classType
    },
    textClassValue() {
      return "title-text text-" + this.classType
    }
  },
  methods: {
    titleClicked() {
      if (this.clickable) {
        this.$emit('titleClicked')
      }
    }
  },
  filters: {
    uppercase: function (value) {
      if (!value) return ''
      value = value.toString()
      value = value.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      return value.toUpperCase()
    }
  },
}
</script>

<style lang="scss">
.jaya-title {
  .title {
    width: 100%;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;

    .title-text {
      margin-left: 1rem;
      margin-top: 11px;
      margin-bottom: 10px;
      color: black;
      font-family: Hortypo;
      font-weight: bolder;
    }

    .text-page-title {
      margin-left: 0;
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .page-title {
    justify-content: center;
  }

  .title-bibli {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .more-info {
    color: var(--vs-primary);
    transform: translateY(-12%);
    font-size: 15px;
    font-weight: bold;
  }
}
</style>
