<template>
  <transition name="slide">
    <div id="jaya-popup" class="jaya-popup">
      <b-modal
          :ref="'jayaPopUpModal-' + _uid"
          :body-class="addPadding ? 'jaya-modal-body px-1' : 'jaya-modal-body'"
          @hide="closeAndRemoveHash"
          content-class="jaya-modal-content"
          header-class="jaya-layout jaya-modal-header"
          hide-footer
          hide-header
          modal-class="jaya-modal"
          scrollable
      >
        <div class="jaya-layout w-100">
          <div class="graphic-top w-100" style="height: 66px;">
            <b-button
                class="close-button"
                variant="primary"
                @click="closeAndRemoveHash"
            >
              <feather-icon
                  icon="XIcon"
                  size="24"
              />
            </b-button>
            <div :class="$store.state.localAuthority.transparent_header ? 'transparent-triangle': ''" class="leftside-triangle"/>
            <div :class="$store.state.localAuthority.transparent_header ? 'transparent-triangle': ''" class="rightside-triangle"/>
            <div class="leftside-triangle-background"/>
            <div class="rightside-triangle-background"/>
          </div>
          <div style="margin-top: 70px;">
            <slot/>
          </div>
        </div>
      </b-modal>
    </div>
  </transition>
</template>

<script type="text/javascript">
import { BButton, BModal } from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BModal,
  },
  props: {
    name: {
      type: String,
      default: 'popup'
    },
    hashUpdate: {
      type: Boolean,
      default: false
    },
    addPadding: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      path: '',
      currentHash: '',
      oldParentStyle: '',
      oldName: ''
    }
  },
  mounted() {
    // remove jayapopup hash when reloading page containing modal
    if (this.$route.hash.includes('#' + this.name)) {
      this.$router.push({ path: this.path })
    } else if (this.$route.hash.includes('-' + this.name)) {
      this.currentHash = this.$route.hash.split('-' + this.name)[0]
      this.$router.push({
        path: this.path,
        hash: this.currentHash
      })
    } else if (this.$route.hash) {
      this.currentHash = this.$route.hash
    }
  },
  methods: {
    scroll() {
      this.scrollPosition = document.getElementById(this.$refs['jayaPopUpModal-' + this._uid].modalBodyId).scrollTop

      let modalContentHeight = document.getElementById(this.$refs['jayaPopUpModal-' + this._uid].modalBodyId).scrollHeight
      if (modalContentHeight - this.scrollPosition - window.innerHeight < 50) {
        this.$emit('scroll-bottom')
      }
    },
    openPopUp() {
      let modal = this.$refs['jayaPopUpModal-' + this._uid]
      if (!this.currentHash || (this.$route.hash && this.currentHash != this.$route.hash)) {
        this.currentHash = this.$route.hash
      }
      this.updateHash()
      modal.show()
      setTimeout(() => {
        document.getElementById(this.$refs['jayaPopUpModal-' + this._uid].modalBodyId)
            .addEventListener('scroll', this.scroll)
      }, 100)
    },
    closePopUp() {
      this.removeScrollListner()
      let modal = this.$refs['jayaPopUpModal-' + this._uid]
      modal.hide()
    },
    emitClose() {
      this.$emit('close')
    },
    removeScrollListner() {
      if (this.$refs['jayaPopUpModal-' + this._uid] && this.$refs['jayaPopUpModal-' + this._uid].isShow) {
        document.getElementById(this.$refs['jayaPopUpModal-' + this._uid].modalBodyId)
            .removeEventListener('scroll', this.scroll)
      }
    },
    updateHash() {
      if (this.$route.hash) {
        this.$router.push({
          path: this.path,
          hash: this.currentHash + '-' + this.name
        })
            .catch(() => {
            })
      } else {
        this.$router.push({
          path: this.path,
          hash: this.name
        })
            .catch(() => {
            })
      }
    },
    removeHash() {
      this.$router.push({
        path: this.path,
        hash: this.currentHash
      }).catch(()=>{});
    },
    closeAndRemoveHash() {
      this.closePopUp()
      this.emitClose()
      this.removeHash()
    }
  },
  watch: {
    '$route.hash'(val) {
      if (!val || !val.includes(this.name)) {
        this.closePopUp()
      }
    }
  }
}
</script>

<style lang="scss">
.jaya-modal .modal-dialog {
  margin: 0;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  height: 100vh;
  max-height: 100vh !important;
  max-width: 100vw;
  display: flex;
  position: fixed;
}

.jaya-modal-content {
  background: #f8f8f8;
  box-shadow: none !important;
  min-height: 100vh !important;

  .jaya-modal-body {
    padding: 0;
    width: 100vw;
    max-width: 100vw;
    overflow-x: hidden !important;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-bottom: 75px;
  }

  .close-button {
    position: absolute;
    top: 0;
    right: 0;
    background-color: rgba(#FFF, 0.9) !important;
    color: var(--primary) !important;
    border: none !important;
    text-align: start !important;
    z-index: 950;
    padding: 10px;
    margin: 22px;
    box-shadow: 0 4px 25px 0 rgb(0 0 0 / 10%);
  }
}
</style>
