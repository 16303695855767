<template>
  <div class="edit-note">
    <JayaTitle :titleName="this.new ? 'Nouvelle note' : 'Éditer la note'" classType="page-title"/>
    <b-form-group
        label="Fait le :"
    >
      <flat-pickr v-model="dateDone" :config="configDateTimePicker" class="form-control"/>
    </b-form-group>

    <b-row v-if="this.new || note.kind === 'Note'" class="justify-content-around align-items-center mt-1">
      <img v-if="notePhoto" :src="notePhoto.url" alt="Photo"
           style="max-width: 100%; max-height: 250px; margin-bottom: 5px;"
      />

      <b-button
          class="h-full"
          variant="outline-primary"
          @click="promptUploadPhoto = true"
      >
        <feather-icon
            icon="CameraIcon"
            size="16"
        />
        <span class="align-middle ml-50"> {{ notePhoto ? 'Modifier la photo' : 'Ajouter une photo' }} </span>
      </b-button>
    </b-row>

    <b-row v-if="this.new || note.kind === 'Note'" class="justify-content-around align-items-center mt-1 max-w-sm">
      <div v-if="dateReminder">
        <feather-icon
            icon="BellIcon"
            size="16"
        />
          {{ new Date(dateReminder).toLocaleDateString('fr-FR') }}
      </div>
      <b-button
          variant="outline-secondary"
          @click="openReminder"
      >
        <feather-icon
            icon="BellIcon"
            size="16"
        />
        <span class="align-middle ml-50"> {{ dateReminder ? 'Modifier le rappel' : 'Ajouter un rappel' }} </span>
      </b-button>
      <b-button
          class="mt-1"
          variant="outline-danger"
          v-if="dateReminder"
          @click="deleteReminder"
      >
        <feather-icon
            icon="TrashIcon"
            size="16"
        />
        <span class="align-middle ml-50"> Retirer le rappel </span>
      </b-button>
    </b-row>

    <b-form-group v-if="this.new || note.kind === 'Note'" label="Note">
      <validation-provider v-slot="{errors}" name="Texte" rules="required|max:65536" immediate>
        <b-form-textarea
            v-model="noteText"
            autofocus
            placeholder="Note"
        />
        <b-form-text class="text-danger">
          {{ errors[0] }}
        </b-form-text>
      </validation-provider>
    </b-form-group>

    <b-row class="d-flex justify-content-around px-1">
      <div v-if="note && note.linkedObject">
        Liée à :
        <br> {{ note.linkedObject }}
      </div>
      <b-form-select v-model="associatedObject">
        <b-form-select-option v-for="(garden, index) of userGardens"
                              :key="index"
                              :class="associatedObject === garden.value ? 'text-primary' : ''"
                              :disabled="garden.disabled"
                              :value="garden.value"
        >
          {{ garden.isVGarden ? '---' : '' }}
          {{ garden.text }}
        </b-form-select-option>
      </b-form-select>
    </b-row>

    <jaya-divider :thickness="1" color="light"/>

    <div class="d-flex justify-content-around">
      <b-button
          v-if="!this.new"
          variant="outline-danger"
          @click="deleteNote"
          class="m-50"
      >
        <feather-icon
            icon="TrashIcon"
            size="16"
        />
        <span class="align-middle ml-50">Supprimer cette note</span>
      </b-button>
      <b-button
          variant="primary"
          :disabled="noteText.length === 0"
          @click="saveNote"
          class="m-50"
      >
        <feather-icon
            icon="SaveIcon"
            size="16"
        />
        <span class="align-middle ml-50">{{ this.new ? 'Valider' : 'Enregristrer' }}</span>
      </b-button>
    </div>

    <JayUpload :active.sync="promptUploadPhoto"
               title="Changer de photo"
               @on-cancel="notePhoto = null"
               @on-error="notePhoto = null"
               @on-success="addPhoto"
    />

    <b-modal
        centered
        title="Ajouter un rappel"
        cancel-title="Annuler"
        cancel-variant="flat-danger"
        v-model="promptReminder"
        ok-title="Valider"
        ok-variant="primary"
        @cancel="cancelReminder"
        @close="cancelReminder"
    >
      <b-form-group
          label="Etre rappelé le :"
      >
        <flat-pickr v-model="dateReminder" :config="configReminderDateTimePicker" class="form-control"/>
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BFormGroup,
  BFormSelect,
  BFormSelectOption,
  BFormText,
  BFormTextarea,
  BModal,
  BRow
} from 'bootstrap-vue'
import FlatPickr from 'vue-flatpickr-component'
import JayaTitle from '@/components/global/JayaTitle'
import JayUpload from '@/components/global/JayUpload'
import { extend, ValidationProvider } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'

extend('required', {
  ...required,
  message: 'Ce champ est requis'
})

export default {
  components: {
    JayaTitle,
    BButton,
    BRow,
    BFormTextarea,
    BFormText,
    BFormGroup,
    BFormSelect,
    BFormSelectOption,
    BModal,
    FlatPickr,
    ValidationProvider,
    JayUpload
  },
  props: {
    new: {
      type: Boolean,
      default: false
    },
    note: {
      type: Object
    },
    linkedObject: {
      type: Object
    }
  },
  data() {
    return {
      noteText: '',
      notePhoto: null,
      dateDone: null,
      oldDateReminder: null,
      dateReminder: null,
      associatedObject: null,
      promptUploadPhoto: false,
      promptReminder: false,

      // Date time settings
      configDateTimePicker: {
        enableTime: false,
        dateFormat: 'Y-m-d'
      },
      configReminderDateTimePicker: {
        minDate: new Date(),
        enableTime: false,
        dateFormat: 'Y-m-d'
      },
    }
  },
  mounted() {
    this.dateDone =  new Date()
    if (this.note) {
      this.dateDone = this.note.date
      this.noteText = this.note.text
      if (this.note.photo) {
        this.notePhoto = {
          url: process.env.VUE_APP_BACKEND_URL + '/..' + this.note.photo_url,
          id: this.note.photo,
          description: '',
        }
      }
      if (this.note.reminders.length > 0) {
        this.dateReminder = this.note.reminders[0].date
      }
    }

    if (this.linkedObject) {
      this.associatedObject = this.linkedObject
    } else {
      this.associatedObject = this.$store.state.garden.gardens.find(garden => garden.is_active)
    }
  },
  computed: {
    userGardens() {
      let gardensArray = [{
        value: null,
        text: 'Sélectionnez un jardin ou une planche potagère comme filtre',
        disabled: true
      }]
      for (const garden of this.$store.state.garden.gardens) {
        let localAuth = garden.local_authority ? ' ' + garden.local_authority_infos.name : ''
        gardensArray.push({
          value: garden,
          text: garden.name + localAuth,
        })
        for (const vGarden of this.$store.state.garden.vgardens.filter(vgarden => vgarden.garden === garden.id)) {
          gardensArray.push({
            value: vGarden,
            text: vGarden.name,
            isVGarden: true,
          })
        }
      }
      return gardensArray
    },
  },
  methods: {
    addPhoto(photo) {
      this.notePhoto = photo
    },
    saveNote() {
      let payload = this.note ? this.note : {}
      payload.date = new Date(this.dateDone)
      payload.title = 'Note perso'
      payload.text = this.noteText
      payload.photo = this.notePhoto ? this.notePhoto.id : null
      if (this.dateReminder) {
        payload.dateReminder = this.dateReminder
      }
      if (this.associatedObject) {
        if (this.associatedObject.vgarden_plants) {
          payload.vegetable_garden = this.associatedObject.id
        } else {
          payload.garden = this.associatedObject.id
          payload.vegetable_garden = null
        }
      } else {
        payload.garden = this.$store.state.garden.gardens.find(garden => garden.is_active)
      }
      if (this.new) {
        this.$store.dispatch('notes/addNote', payload)
            .then((response) => {
              if (this.dateReminder) {
                this.addReminder(response)
              }
              this.$notify({
                time: 4000,
                title: 'Note ajoutée',
                color: 'primary'
              })
              this.$emit('new-note')
              this.$emit('close')
            })
            .catch(() => {
              this.$notify({
                time: 4000,
                title: 'Erreur',
                text: 'Erreur dans l\'enregistrement de la note, veuillez réessayer ulterieurement',
                color: 'danger'
              })
            })
      } else {
        this.$store.dispatch('notes/editNote', payload)
            .then((response) => {
              if (this.dateReminder) {
                this.addReminder(response)
              }
              this.$notify({
                time: 4000,
                title: 'Note enregistrée',
                color: 'primary'
              })
              this.$emit('close')
            })
            .catch(() => {
              this.$notify({
                time: 4000,
                title: 'Erreur',
                text: 'Erreur dans l\'enregistrement de la note, veuillez réessayer ulterieurement',
                color: 'danger'
              })
            })
      }
    },
    deleteNote() {
      this.$store.dispatch('notes/deleteNote', { id: this.note.id })
          .then(() => {
            this.$notify({
              time: 4000,
              title: 'Note supprimée',
              color: 'primary'
            })
            this.$emit('close')
          })
          .catch(() => {
            this.$notify({
              time: 4000,
              title: 'Erreur',
              text: 'Impossible de supprimer la note, veuillez réessayer ulterieurement',
              color: 'danger'
            })
          })
    },
    openReminder() {
      this.oldDateReminder = this.dateReminder
      if (!this.dateReminder)  {
        this.dateReminder = new Date()
      }
      this.promptReminder = true
    },
    cancelReminder() {
      this.dateReminder = this.oldDateReminder
      this.promptReminder = false
    },
    addReminder(note) {
      let reminderPayload = {
        note: note.id,
        date: new Date(this.dateReminder)
      }
      if (note.reminders.length > 0) {
        reminderPayload.reminderId = note.reminders[0].id
        this.$store.dispatch('notes/editReminder', reminderPayload)
            .catch(() => {
              this.$notify({
                time: 4000,
                title: 'Erreur',
                text: 'Erreur dans la modification du rappel, veuillez réessayer ulterieurement',
                color: 'danger'
              })
            })

      }
      this.$store.dispatch('notes/addReminder', reminderPayload)
          .catch(() => {
            this.$notify({
              time: 4000,
              title: 'Erreur',
              text: 'Erreur dans l\'enregistrement du rappel, veuillez réessayer ulterieurement',
              color: 'danger'
            })
          })
    },
    deleteReminder() {
      this.dateReminder = null
      if (this.note.reminders.length > 0) {
        this.$store.dispatch('notes/deleteReminder', {
          note: this.note.id,
          id: this.note.reminders[0].id
        })
      }
    }
  }
}
</script>
