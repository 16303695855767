<template>
  <div>
  <b-card body-class="p-0" class="mb-50" @click="animalCardClick">
    <b-row no-gutters style="flex-wrap: nowrap;">

      <b-col :class="observation.kind === 'Note' ? 'flex-grow-1' : ''" class="d-flex flex-column justify-content-center col col-md-4 mr-50">

        <div v-if="observation.image_urls.length || observation.taxon_details.photos.length" style="height: 110px" class="d-flex flex-column justify-content-center">
          <b-card-img
              overlay
              alt="Image de l'observation"
              :src="getImageUrl()"
              style="max-height: 110px;"
              class="card-img-left jaya-card-image"
              left
          />
        </div>
      </b-col>
      <b-col class="col-8 col-md-6 d-flex">
        <div class="d-flex flex-column align-items-center justify-content-center flex-wrap w-100 my-50 text-center">
          <div class="font-weight-bolder font-medium-2">
            {{ observation.taxon_details.common_name }}
          </div>
          <div class="font-italic font-medium-2">
            {{ observation.taxon_details.infos_taxon.lb_nom }}
          </div>
          <div class="mt-50"> Le {{
              new Date(observation.timestamp).toLocaleDateString('fr-FR')
            }}

          </div>
          <div>
            <i class="text-right text-info">{{ getGardenName() }}</i>
          </div>
        </div>
      </b-col>
    </b-row>

    <b-modal
        v-model="editNote"
        centered
        hide-footer
        scrollable
        size="lg"
        title="Editer la note"
    >
      <NoteEdit :note="observation" :linkedObject="observationInfo.linkedObject" @close="editNote = false"/>
    </b-modal>
  </b-card>
  <JayaPopUp id="PopUpObservation" ref="popupRef" title="Observation">
    <b-card class="max-w-md">
      <HortiliObservation mode="display" :taxon="observation.taxon_details" :initial-data="observation" @observationDone="closePopUpAnimalObservation"/>
    </b-card>
  </JayaPopUp>
  </div>
</template>

<script>
import { BCard, BCardImg, BCol, BModal, BRow } from 'bootstrap-vue'
import NoteEdit from '@/components/notes/NoteEdit'
import HortiliObservation from "@/components/animals/HortiliObservation.vue";
import JayaPopUp from "@/components/global/JayaPopUp.vue";
export default {
  components: {
    JayaPopUp,
    HortiliObservation,
    BModal,
    BCard,
    BRow,
    BCol,
    BCardImg,
    NoteEdit,
  },
  props: {
    observation: {
      required: true,
      type: Object
    },
  },
  data() {
    return {
      editNote: false,
    }
  },
  computed: {
    observationInfo() {
      let infos = {
        color: 'primary',
        image: {
          src: 'tools/shovel',
          alt: 'icone pelle'
        },
        image2: {},
      }

      if(this.observation.quantity > 1) {
        infos.text1 += ' x' + this.observation.quantity.toString()
      }

      infos.linkedObject = this.$store.state.garden.gardens.find(garden => garden.id === this.observation.garden)

      let img = ''
      try {
        img = require('@/assets/images/icons/' + infos.image.src + '.svg')
      } finally {
        // do nothing
      }
      infos.image.src = img

      let img2 = ''
      if (infos.image2.src) {
        try {
          img2 = require('@/assets/images/icons/' + infos.image2.src + '.svg')
        } finally {
          // do nothing
        }
        infos.image2.src = img2
      }
      if (this.observation.image_urls.length) {
        infos.picture = {
          src: null,
          alt: ''
        }
        infos.picture.src = process.env.VUE_APP_BACKEND_URL + '/..' + this.observation.image_urls[0] + '&size=600'
        infos.picture.alt = 'photo utilisateur'
      }

      return infos

    }
  },
  methods: {
    getImageUrl() {
      let url = this.observation.image_urls[0]
      if (!url) {
        url = this.observation.taxon_details.photos[0].photo_url
      }
      return process.env.VUE_APP_BACKEND_URL + '/..' + url + '&size=600'
    },
    getGardenName() {
      return this.$store.state.garden.gardens.find(garden => garden.id === this.observation.garden).name
    },
    openMoreInfo() {
      this.$refs.popupRef.openPopUp()
      this.popUpMoreInfo = true
    },
    animalCardClick() {
      this.openMoreInfo()
    },
    closePopUpAnimalObservation (e){
      this.popUpMoreInfo = false
      this.$refs.popupRef.closePopUp()
      if (e === 'edit') {
        this.$notify(
            {
              title: "Observation",
              text: "Observation mise à jour",
              time: 4000
            })
      }
      if (e === 'delete') {
        this.$notify(
            {
              title: "Observation",
              text: "Observation supprimée",
              time: 4000,
              color: 'danger'
            })
      }
    }
  }
}
</script>

<style lang="scss">
.note-icon {
  height: 40px;
  width: 40px;
  @media (min-width: 768px) {
    height: 60px;
    width: 60px;
  }
}

.note-icon-small {
  height: 20px;
  width: 20px;
  @media (min-width: 768px) {
    height: 25px;
    width: 25px;
  }
}
</style>
